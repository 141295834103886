import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICommerceApiSettings, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { ProductAvailableQuantity, ProductWarehouse, ProductWarehouseInventoryAvailability } from '@msdyn365-commerce/retail-proxy';
import { getOrgUnitConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import { getEstimatedProductWarehouseAvailabilityAsync, getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { buildCacheKey, } from './index';
import {ActiveCartProductsInput, getActiveCartProductsAction} from './index';

/**
 * Input class for availabilites for items in cart
 */
export class ProductAvailabilitiesForCartLineItems implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ActiveCartLineItemsAvailability`, this.apiSettings);
    public getCacheObjectType = () => 'ActiveCartLineItemsAvailability';
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>) => {
    return new ProductAvailabilitiesForCartLineItems(inputData.requestContext.apiSettings);
};

/**
 * Calls the Retail API to get the product availabilites for items in the cart
 */
// tslint:disable-next-line:cyclomatic-complexity tslint:disable: max-func-body-length
export async function getAvailabilitiesForCartLineItems(input: ProductAvailabilitiesForCartLineItems, ctx: IActionContext): Promise<ProductAvailableQuantity[]> {
    // If no input is provided fail out
    if (!input) {
        throw new Error('[getAvailabilitiesForCartLineItems]No valid Input was provided, failing');
    }
    const shippingItems = [];
    const bopisItems = [];
    let productAvailabilities:ProductAvailableQuantity[] = [];

    const cartState = await getCartState(ctx);
    const cart = cartState.cart;
    const channelConfiguration = await getOrgUnitConfigurationAsync({ callerContext: ctx});
    const products = await getActiveCartProductsAction(new ActiveCartProductsInput(), ctx);

    if(!cart || !channelConfiguration || !products || products.length === 0) {
        ctx.trace('[getAvailabilitiesForCartLineItems] Not able to get cart OR channelConfiguration or no products in cart');
        return <ProductAvailableQuantity[]>[];
    }

    if(cart && cart.Id && cart.CartLines && cart.CartLines.length > 0 && channelConfiguration) {

        for(const cartLine of cart.CartLines) {
            if(cartLine.DeliveryMode === channelConfiguration.PickupDeliveryModeCode) {
                bopisItems.push(cartLine);
            } else {
                shippingItems.push(cartLine);
            }
        }
    }

    if(shippingItems && shippingItems.length > 0) {
        const shippingProductAvailabilites = await getEstimatedAvailabilityAsync({ callerContext: ctx}, { ProductIds: shippingItems.map(x => x.ProductId!), DefaultWarehouseOnly: true});
        if(shippingProductAvailabilites && shippingProductAvailabilites.ProductWarehouseInventoryAvailabilities && shippingProductAvailabilites.ProductWarehouseInventoryAvailabilities.length > 0) {
            productAvailabilities = mergeProductWarehouseAvailToProductAvailabilities(shippingProductAvailabilites.ProductWarehouseInventoryAvailabilities, productAvailabilities);
        }
    }

    if(bopisItems && bopisItems.length > 0) {

        for(const bopisItem of bopisItems) {
            const productWarehouse: ProductWarehouse = {
                ProductId: bopisItem.ProductId,
                InventLocationId: bopisItem.WarehouseId
            };
            const getProductWarehouseAvail = await getEstimatedProductWarehouseAvailabilityAsync({ callerContext: ctx, queryResultSettings: {} }, [productWarehouse]);
            if(getProductWarehouseAvail && getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities && getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities.length > 0) {
                productAvailabilities = mergeProductWarehouseAvailToProductAvailabilities(getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities, productAvailabilities);
            }
        }
    }

    if(productAvailabilities && productAvailabilities.length > 0) {
        return productAvailabilities;
    } else {
        ctx.trace('[getAvailabilitiesForCartLineItems] unable to get availabilites for product');
        return <ProductAvailableQuantity[]>[];
    }
}

function mergeProductWarehouseAvailToProductAvailabilities(sourceOrgUnitAvailabilityArray: ProductWarehouseInventoryAvailability[], destinationAvailabilityArray: ProductAvailableQuantity[]): ProductAvailableQuantity[] {
    if(sourceOrgUnitAvailabilityArray && sourceOrgUnitAvailabilityArray.length > 0) {
        for(const product of sourceOrgUnitAvailabilityArray){
            if(product.TotalAvailable !== undefined && product.ProductId !== undefined) {
                destinationAvailabilityArray.push({ ProductId: product.ProductId, AvailableQuantity: product.TotalAvailable });
            }
        }
    }

    return destinationAvailabilityArray;
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-availabilities-cartlines',
    action: <IAction<ProductAvailableQuantity[]>>getAvailabilitiesForCartLineItems,
    input: createInput
});
