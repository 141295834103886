import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICommerceApiSettings, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { ProductAvailableQuantity, ProductDeliveryOptions } from '@msdyn365-commerce/retail-proxy';
import { getDeliveryOptionsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { buildCacheKey, } from './index';
import {ActiveCartProductsInput, getActiveCartProductsAction} from './index';

/**
 * Input class for delivery options for items in cart
 */
export class ProductDeliveryOptionsForCartLineItems implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ActiveCartLineItemsDeliveryOptions`, this.apiSettings);
    public getCacheObjectType = () => 'ActiveCartLineItemsDeliveryOptions';
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>) => {
    return new ProductDeliveryOptionsForCartLineItems(inputData.requestContext.apiSettings);
};

/**
 * Calls the Retail API to get the delivery options for items in the cart
 */
export async function getDeliveryOptionsForCartLineItems(input: ProductDeliveryOptionsForCartLineItems, ctx: IActionContext): Promise<ProductDeliveryOptions[]> {
    // If no input is provided fail out
    if (!input) {
        throw new Error('[getDeliveryOptionsForCartLineItems]No valid Input was provided, failing');
    }
    const cartState = await getCartState(ctx);
    const cart = cartState.cart;
    const products = await getActiveCartProductsAction(new ActiveCartProductsInput(), ctx);

    if(!cart || !products || products.length === 0) {
        console.log('[getDeliveryOptionsForCartLineItems] Not able to get cart OR channelConfiguration or no products in cart');
        ctx.trace('[getDeliveryOptionsForCartLineItems] Not able to get cart OR channelConfiguration or no products in cart');
        return <ProductDeliveryOptions[]>[];
    }

    return getDeliveryOptionsAsync({ callerContext: ctx, queryResultSettings: {} }, products.map(x => x.RecordId), {})
        .then(result => {
            return result;
        }).catch((error: Error) => {
            ctx.trace(error.message);
            throw new Error('[getDeliveryOptionsForCartLineItems]Error executing action');
        });
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-delivery-options-for-cartlines',
    action: <IAction<ProductAvailableQuantity[]>>getDeliveryOptionsForCartLineItems,
    input: createInput
});
