import { action } from 'mobx';

import { IActionContext } from '@msdyn365-commerce/core';
import { BaseStoreSelectorStateManager } from './base-store-selector-state-manager';
import { IStoreSelectorLocation } from './i-store-selection-state-manager';
import { BingMapsLocationsResult } from './models/bing-maps-data';

/**
 * Instance of IStoreSelectorStateManager manager that uses Bing Maps service
 * for geo location
 */
export class BingMapsStoreSelectorStateManager extends BaseStoreSelectorStateManager {
    private readonly SERVICE_ENDPOINT: string = 'https://dev.virtualearth.net/REST/v1/';

    @action
    public async geoLocate(searchTerm: string, actionContext: IActionContext): Promise<IStoreSelectorLocation | undefined> {
        if (!searchTerm ||
            !actionContext.requestContext.channel ||
            !actionContext.requestContext.channel.BingMapsApiKey ||
            !actionContext.requestContext.channel.BingMapsEnabled) {
                return undefined;
        }

        const jsonp = `bingMapsSearchCallback_${crypto.getRandomValues(new Uint32Array(1))[0]}`;
        const query = encodeURI(searchTerm);
        const key = encodeURI(actionContext.requestContext.channel.BingMapsApiKey);

        const requestUrl =
            `${this.SERVICE_ENDPOINT}/Locations?query=${query}&key=${key}&jsonp=${jsonp}`;

        return new Promise<IStoreSelectorLocation | undefined>((resolve) => {
            const script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('src', requestUrl);

            window[jsonp] = (data: BingMapsLocationsResult) => {
                delete window[jsonp];
                document.body.removeChild(script);

                if (data && data.resourceSets && data.resourceSets.length > 0 && data.resourceSets[0].resources.length > 0) {
                    resolve({
                        latitude: data.resourceSets[0].resources[0].point.coordinates[0],
                        longitude: data.resourceSets[0].resources[0].point.coordinates[1]
                    });
                }

                resolve(undefined);
            };

            document.body.appendChild(script);
        });
    }
}