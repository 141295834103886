import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { getUrlSync, IActionContext } from '@msdyn365-commerce/core';
import { parameterize } from './parameterize';

interface ICategoryMap {
    [RecordId: number]: CategoryHierarchy;
}

/**
 * Builds slugs for category
 */
export function getCategorySlug(category: CategoryHierarchy, categoryMap: ICategoryMap): string {
    if (!category || !category.Name) {
        // invalid category
        return '';
    }
    const categoryName = parameterize(category.Name);
    if (!category.ParentCategory || category.ParentCategory === 0) {
        // base case assign url
        category.Slug = category.Slug || `/${categoryName}`;
    } else if (category.ParentCategory) {
        category.Slug = category.Slug || `${getCategorySlug(categoryMap[category.ParentCategory], categoryMap)}/${categoryName}`;
    }

    return category.Slug || '';
}

/**
 * Gets Url for category hierarchy. Returns string and adds url to Url property of category hierarchy
 * @param category Category to generate URL for
 * @param categoryMap Dictionary of all categories
 */
export function getCategoryUrl(category: CategoryHierarchy, ctx?: IActionContext, categoryMap?: ICategoryMap): string | undefined {
    if (!category.Url && ctx && categoryMap) {
        if (!category.Slug) {
            category.Slug = getCategorySlug(category, categoryMap);
        }
        category.Url = getUrlSync('category', ctx, { category });
    }
    return category.Url;
}
