import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric } from '@msdyn365-commerce/core';

import { OrgUnitAvailability, SearchArea, StoreHours } from '@msdyn365-commerce/retail-proxy';
import { getAvailableInventoryNearbyAsync, getStoreHoursAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';

import { IFullOrgUnitAvailability } from '../common/full-org-unit-availability';

/**
 * Get selected variant action input class
 */
export class GetFullAvilableInventoryNearbyInput implements IActionInput {
   public latitude?: number;
   public longitude?: number;
   public radius?: number;
   public productId?: number;

   constructor(_productId?: number, _latitude?: number, _longitude?: number, _radius?: number) {
       this.productId = _productId;
       this.latitude = _latitude;
       this.longitude = _longitude;
       this.radius = _radius;
   }

   public getCacheKey = () => `GetFullAvilableInventoryNearby`;
   public getCacheObjectType = () => 'FullOrgUnitAvailabilities';
   public dataCacheType = (): CacheType => 'none';
}

/**
 * CreateInput method for the getSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetFullAvilableInventoryNearbyInput => {
    return new GetFullAvilableInventoryNearbyInput();
};

/**
 * Action method for the getSelectedVariant data aciton
 * @param input The action input class
 * @param ctx The action context
 */
async function getFullAvilableInventoryNearby(input: GetFullAvilableInventoryNearbyInput, ctx: IActionContext): Promise<IFullOrgUnitAvailability[] | undefined> {
    // No valid product we want to return undefined so module knows there are no results yet
    if (!input.productId) {
        return undefined;
    }

    if (!input.radius || !input.latitude || !input.longitude) {
        // No valid location we want to return empty array so module can show no locations message
        return [];
    }

    const searchArea: SearchArea = {
        Latitude: input.latitude,
        Longitude: input.longitude,
        Radius: input.radius,
        DistanceUnitValue: 0 // 0 is miles
    };

    return getAvailableInventoryNearbyAsync({ callerContext: ctx, queryResultSettings: {} }, [{Product: input.productId}], searchArea)
        .then(async (availabilities: OrgUnitAvailability[]) => {
            if (availabilities && !(availabilities instanceof Error)) {
                const availabilityPromiseList = availabilities.map(availability => _getAvailabilityWithHours(availability, ctx));

                return Promise.all(availabilityPromiseList);
            } else {
                return [];
            }
        })
        .catch((error: Error) => {
            ctx.trace('[GetFullAvilableInventoryNearby] error getting Available Inventory Nearby');
            ctx.trace(error.message);

            return [];
        });
}

async function _getAvailabilityWithHours(availability: OrgUnitAvailability, ctx: IActionContext): Promise<IFullOrgUnitAvailability> {
    if (!availability || !availability.OrgUnitLocation || !availability.OrgUnitLocation.OrgUnitNumber) {
        return { OrgUnitAvailability: availability };
    }

    return getStoreHoursAsync({ callerContext: ctx}, availability.OrgUnitLocation.OrgUnitNumber)
        .then((hours: StoreHours) => {
            if (hours && !(hours instanceof Error)) {
                return { OrgUnitAvailability: availability, StoreHours: hours };
            }

            return { OrgUnitAvailability: availability };
        })
        .catch((error: Error) => {
            ctx.trace('[GetFullAvilableInventoryNearby] error getting availability with hours');
            ctx.trace(error.message);

            return { OrgUnitAvailability: availability };
        });
}

/**
 * The complete getSelectedVariant data action
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/bopis/get-full-available-inventory-nearby',
    action: <IAction<IFullOrgUnitAvailability[] | undefined>>getFullAvilableInventoryNearby,
    input: createInput
});
