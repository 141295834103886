import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { LoyaltyCard } from '@msdyn365-commerce/retail-proxy';
import { getCustomerLoyaltyCardsAsync, getLoyaltyCardAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import { buildCacheKey } from './index';

/**
 *  Input class for the getLoyaltyCard data action
 */
export class GetLoyaltyCardInput implements IActionInput {
    public userAccountNumber: string;
    public apiSettings: ICommerceApiSettings;

    constructor(userAccountNumber: string, apiSettings: ICommerceApiSettings) {
        this.userAccountNumber = userAccountNumber;
        this.apiSettings = apiSettings;

    }

    public getCacheKey = () => buildCacheKey(`${this.userAccountNumber}`, this.apiSettings);
    public getCacheObjectType = () => 'GetLoyaltyCard';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * createInput method for the getLoyaltyCard method
 * @param inputData The input data passed to the createInput method
 */
export const createGetLoyaltyCardInput = (inputData: ICreateActionContext): GetLoyaltyCardInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated || !requestContext.user.customerAccountNumber) {
        throw new Error('Unable to create get loyalty card input.  User is not authenticated.');
    }

    return new GetLoyaltyCardInput(requestContext.user.customerAccountNumber, inputData.requestContext.apiSettings);
};

/**
 * The action method for the getLoyaltyCard data action
 * @param input The action input
 * @param ctx The action context
 */
export async function getLoyaltyAction(input: GetLoyaltyCardInput, ctx: IActionContext): Promise<LoyaltyCard> {
    return getCustomerLoyaltyCardsAsync({ callerContext: ctx, queryResultSettings: {} }, input.userAccountNumber)
            .then(cards => {
                if (!cards || cards.length < 1) {
                    return <LoyaltyCard>{};
                }

                const firstCard = cards[0];

                return getLoyaltyCardAsync({ callerContext: ctx }, firstCard.CardNumber!)
                    .then(card => {
                        card.LoyaltyEnrollmentDate = firstCard.LoyaltyEnrollmentDate;
                        card.LoyaltyEnrollmentDateLocal = firstCard.LoyaltyEnrollmentDateLocal;
                        return card;
                    })

                    .catch(error => {
                        throw new Error('Unable to get loyalty card');
                    });
            })
            .catch(error => {
                throw new Error('Unable to get customers loyalty card');
            });
}

/**
 * The getLoyaltyCard data action
 * Returns the loyalty card belonging to the customer
 */
export default createObservableDataAction<LoyaltyCard>({
    id: '@msdyn365-commerce-modules/retail-actions/get-loyalty-card',
    action: <IAction<LoyaltyCard>>getLoyaltyAction,
    input: <(args: ICreateActionContext) => IActionInput>createGetLoyaltyCardInput
});
