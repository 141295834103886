import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric, IRequestContext } from '@msdyn365-commerce/core';
import { buildCacheKey } from '.';
import getCategoryHierarchy, { CategoriesInput } from './get-categories-hierarchy';
import { parameterize } from './utilities/Url-builder';

/**
 * Input class for GetCurrentCategory data action
 */
export class CurrentCategoryInput implements IActionInput {
    public categoryId?: number;
    public categorySlug?: string;
    private apiSettings: ICommerceApiSettings;

    constructor(context: IRequestContext) {
        let categoryId;
        let categorySlug;
        if (context.query && context.query.categoryId) {
            categoryId = Number(context.query.categoryId);

            if (Number.isNaN(categoryId)) {
                throw new Error('Failed to cast category id into a number.');
            }
        // @ts-ignore: RecordId URLToken not typed yet
        } else if (context.urlTokens && context.urlTokens.recordId) {
        // @ts-ignore: RecordId URLToken not typed yet
            categoryId = Number(context.urlTokens.recordId);
            if (Number.isNaN(categoryId)) {
                throw new Error('Failed to cast category id into a number.');
            }
        } else if (context.urlTokens && context.urlTokens.categories && context.urlTokens.categories.length) {
            const categories: string[] = <string[]>(<unknown>context.urlTokens.categories);

            if (!categories.length) {
                throw new Error('Failed to get category name from urlTokens');
            }

            categorySlug = `/${categories
                .map(category => {
                    return parameterize(category);
                })
                .join('/')}`;
        }
        this.categoryId = categoryId;
        this.categorySlug = categorySlug;
        this.apiSettings = context.apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`${this.categoryId || this.categorySlug}`, this.apiSettings);
    public getCacheObjectType = () => 'Current-Category';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * Creates a current category input from an ICreateActionContext
 */
export const createGetCurrentCategoryInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    if (inputData && inputData.requestContext) {
        return new CurrentCategoryInput(inputData.requestContext);
    }

    throw new Error('Please specify categoryId query string in request.');
};

/**
 * Rescrusive Search Method to find a given category amongts a complete CategoryHierarcy
 * @param categories The current Category Hierarchy
 * @param categorySlug The category slug being searched for
 * @param categoryId The category Id being search for
 */
export function searchCategory(categories: CategoryHierarchy[], categorySlug?: string, categoryId?: number): CategoryHierarchy | undefined {
    let foundCategory;
    categories.forEach((cat: CategoryHierarchy) => {
        if ((categoryId && cat.RecordId === categoryId) || (categorySlug && cat.Slug === categorySlug)) {
            return (foundCategory = cat);
        } else if (cat.Children && cat.Children.length) {
            const matchingChild = searchCategory(cat.Children, categorySlug, categoryId);
            if (matchingChild) {
                foundCategory = matchingChild;
            }
        }
    });
    return foundCategory;
}

/**
 * Action method for the getCurrentCategory Data Action
 * @param input The action input class
 * @param ctx The action context
 */
export async function getCurrentCategoryAction(input: CurrentCategoryInput, ctx: IActionContext): Promise<CategoryHierarchy | undefined> {
    if (input.categorySlug || input.categoryId) {
        const categoryInput = new CategoriesInput(ctx.requestContext, true);
        const categories = await getCategoryHierarchy(categoryInput, ctx);
        if (!categories) {
            ctx.trace('[getCurrentCategory] Unable to get categories from server');
            return;
        }

        const category = searchCategory(categories, input.categorySlug, input.categoryId);
        if (!category) {
            ctx.trace('[getCurrentCategory] Unable to find category');
            return;
        }
        return category;
    }
}

/**
 * The getCurrentCategory data action
 * First makes a call to the getCategories RetailServer API
 * to get a list of every available category, then returns a CategoryHierarchy
 * based on the categoryId query param set in the URL of the request
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-current-category',
    action: <IAction<CategoryHierarchy>>getCurrentCategoryAction,
    input: createGetCurrentCategoryInput
});
