import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { CacheType, IAction, IActionContext, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext, IGeneric, IRequestContext } from '@msdyn365-commerce/core';
import { Category } from '@msdyn365-commerce/retail-proxy';
import getCategoryAction, { CategoriesInput as RawCategoriesInput } from './get-categories';
import { getCategoryUrl } from './utilities/Url-builder';
import { buildCacheKey } from './utilities/utils';

/**
 * Input for get-categories data action
 */
export class CategoriesInput implements IActionInput {
    public readonly maxItems: number;
    public readonly channelId: number;
    private readonly sitePath: string;
    private _mappedToHierarchy: boolean;
    private apiSettings: ICommerceApiSettings;

    constructor(context: IRequestContext, mappedToHierarchy: boolean, maxItems?: number) {
        this._mappedToHierarchy = mappedToHierarchy;
        this.maxItems = maxItems || 250;
        this.channelId = context && context.apiSettings && context.apiSettings.channelId ? +context.apiSettings.channelId : 0;
        this.sitePath = context && context.sitePath || '';
        this.apiSettings = context.apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`${this.channelId}|${this.sitePath}|top-${this.maxItems || 250}`, this.apiSettings);
    public getCacheObjectType = () => (this._mappedToHierarchy ? 'CategoryHierarchy' : 'Category');
    public dataCacheType = (): CacheType => 'application';
}

export interface ICategoryMap {
    [RecordId: number]: CategoryHierarchy;
}

/**
 * Creates a hierarchy of categories based on the ParentCategory property
 * @param categoryList Categories that will be converted into a hierarchy
 * @returns Hierarchy of categories in array
 */
export const mapCategoryToHierarchy = (categoryList: Category[], ctx: IActionContext): CategoryHierarchy[] => {
    if (!categoryList || !categoryList.length) {
        return [];
    }

    const categoryMap: ICategoryMap = categoryList.reduce((memo: ICategoryMap, category: Category) => {
        memo[category.RecordId] = <CategoryHierarchy> { ...category };
        return memo;
    }, {});

    let zeroCategory = categoryMap[0];

    Object.keys(categoryMap).forEach((id: string) => {
        const element = categoryMap[+id];
        const parentId = element.ParentCategory;
        element.Url = getCategoryUrl(element, ctx, categoryMap);
        if (parentId === 0) {
            zeroCategory = element;
            return;
        }

        const parent = parentId && categoryMap[parentId];
        if (parent) {
            parent.Children = parent.Children || [];
            parent.Children.push(element);
        }
    });

    return (zeroCategory && zeroCategory.Children) || [];
};

/**
 * Creates the input required to make the retail api call
 */
export const createCategoriesHierarchyInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const topItems = inputData.config && inputData.config.topCategories && parseInt(inputData.config.topCategories, 10);
    return new CategoriesInput(inputData.requestContext, true, topItems);
};

/**
 * Calls the Retail API and returns all the categories as a hierarchy
 */
export async function getCategoryHierarchyAction(input: CategoriesInput, ctx: IActionContext): Promise<CategoryHierarchy[]> {
    const categories = await getCategoryAction(
        new RawCategoriesInput(ctx.requestContext, false, input.maxItems),
        ctx
    );
    return mapCategoryToHierarchy(categories, ctx);
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-categories-hierarchy',
    action: <IAction<CategoryHierarchy[]>>getCategoryHierarchyAction,
    input: createCategoriesHierarchyInput
});
