import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductDeliveryOptions, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

import { getDeliveryOptionsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { getSelectedProductIdFromActionInput, getSelectedVariant, SelectedVariantInput } from './index';

/**
 * Input class for the GetDeliveryOptionsForSelectedVariant Data Action
 */
export class GetDeliveryOptionsForSelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public selectedProduct: SimpleProduct | undefined;
    public customerId: string;

    constructor(productId: number, channelId: number, selectedProduct?: SimpleProduct, customerId?: string) {
        this.productId = productId;
        this.channelId = channelId;
        this.selectedProduct = selectedProduct;
        this.customerId = customerId || '';
    }

    public getCacheKey = () => `DeliveryOptionsForSelectedVariant`;
    public getCacheObjectType = () => 'DeliveryOptions';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * The createInput method for the GetDeliveryOptionsForSelectedVariant Data Action
 * @param inputData The input data for the createInput method
 */
export const createDeliveryOptionsForSelectedVariantInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetDeliveryOptionsForSelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new GetDeliveryOptionsForSelectedVariantInput(
            +productId,
            +inputData.requestContext.apiSettings.channelId,
            undefined,
            inputData.requestContext.user.customerAccountNumber);
    } else {
        throw new Error('Unable to create PriceForSelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * The Action Method for the GetDeliveryOptionsForSelectedVariant Data Action
 * Pulls the currently selected variant from the cache using the getSelectedVariant data action, and gets it's supported delivery options
 * via the getDeliveryOptionsAsync RetailServer API
 */
export async function getDeliveryOptionsForSelectedVariantAction(
    input: GetDeliveryOptionsForSelectedVariantInput,
    ctx: IActionContext
): Promise<ProductDeliveryOptions | undefined> {
    return Promise.resolve()
        // @ts-ignore: Promise vs. ObservablePromise typing conflict
        .then(() => {
            const activeProduct: SimpleProduct | undefined = input.selectedProduct;

            if (!activeProduct) {
                const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);

                return getSelectedVariant(selectedVariantInput, ctx);
            }

            return activeProduct;
        })
        .then((productResult: SimpleProduct | null) => {
            const activeProduct: SimpleProduct | undefined = <SimpleProduct | undefined>productResult;

            if (activeProduct) {
                return getDeliveryOptionsAsync({ callerContext: ctx, queryResultSettings: {} }, [activeProduct.RecordId], {})
                    .then(response => {
                        if(response && response.length > 0) {
                            return response[0];
                        }

                        return undefined;
                    });
            }

            return undefined;
        })
        .catch((error: Error) => {
            ctx.trace(error.message);
            throw new Error('[getDeliveryOptionsForSelectedVariantAction]Error executing action');
        });
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-delivery-options-for-selected-variant',
    action: <IAction<ProductDeliveryOptions | undefined>>getDeliveryOptionsForSelectedVariantAction,
    input: createDeliveryOptionsForSelectedVariantInput
});
