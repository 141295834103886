import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICommerceApiSettings, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductAvailableQuantity, ProductWarehouseInventoryAvailability } from '@msdyn365-commerce/retail-proxy';
import { getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ActiveWishlistInput, getActiveWishlistItems } from './get-items-in-wishlists';

/**
 * Input class for availabilites for items in wishlist
 */
export class ProductAvailabilitiesForWishlistItems implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ActiveWishlistItemsAvailability`, this.apiSettings);
    public getCacheObjectType = () => `ActiveWishlistItemsAvailability`;
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>) => {
    return new ProductAvailabilitiesForWishlistItems(inputData.requestContext.apiSettings);
};

/**
 * Calls the Retail API to get wishlist which will be cached the then finds the quantities for each item
 */
export async function getAvailabilitiesForWishlistItems(input: ProductAvailabilitiesForWishlistItems, ctx: IActionContext): Promise<ProductAvailableQuantity[]> {
    // If no input is provided fail out
    if (!input) {
        throw new Error('[getAvailabilitiesForWishlistItems]No valid Input was provided, failing');
    }

    const products = await getActiveWishlistItems(new ActiveWishlistInput(), ctx);

    if(!products) {
        ctx.trace('[getAvailabilitiesForWishlistItems] Not able to get products in wishlist');
        return <ProductAvailableQuantity[]>[];
    }

    const validProducts = [];

    for(const product of products) {
        if(product.ProductDetails && product.ProductDetails.RecordId) {
            validProducts.push(product.ProductDetails.RecordId);
        }
    }

    if (validProducts.length === 0) {
        ctx.trace('[getAvailabilitiesForWishlistItems] No products in wishlist');
        return <ProductAvailableQuantity[]>[];
    }

    const productAvailabilites = await getEstimatedAvailabilityAsync({callerContext:ctx}, {ProductIds: validProducts, DefaultWarehouseOnly:true});

    if(productAvailabilites && productAvailabilites.ProductWarehouseInventoryAvailabilities && productAvailabilites.ProductWarehouseInventoryAvailabilities.length > 0) {
        return mergeProductWarehouseToProductAvailabities(productAvailabilites.ProductWarehouseInventoryAvailabilities);
    } else {
        ctx.trace('[getAvailabilitiesForWishlistItems] unable to get availabilites for product');
        return <ProductAvailableQuantity[]>[];
    }
}

export function mergeProductWarehouseToProductAvailabities(productsWarehouse: ProductWarehouseInventoryAvailability[]): ProductAvailableQuantity[] {
    const productAvailable: ProductAvailableQuantity[] = [];
    if(productsWarehouse && productsWarehouse.length > 0) {
        for(const product of productsWarehouse) {
            if(product.TotalAvailable !== undefined && product.ProductId !== undefined) {
                productAvailable.push({ProductId: product.ProductId, AvailableQuantity: product.TotalAvailable});
            }
        }
    }
    return productAvailable;
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/wishlist/wishlist-items/get-product-availabilities-wishlist-items',
    action: <IAction<ProductAvailableQuantity[]>>getAvailabilitiesForWishlistItems,
    input: createInput
});